import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Motion_Detection/INSTAR_Cloud/IFTTT/NavButtons';
import ForumBox from 'components/Motion_Detection/INSTAR_Cloud/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR Cloud IFTTT User Guide",
  "path": "/Motion_Detection/INSTAR_Cloud/IFTTT/User_Guide/How_to_use_INSTAR_Applets/",
  "dateChanged": "2020-10-21",
  "author": "Mike Polinowski",
  "excerpt": "On the IFTTT Platform, we provided a service named INSTAR. The INSTAR Applets provides a way for you to control some settings of your INSTAR Camera or INSTAR Cloud with IFTTT. You can connect INSTAR to Location and Date & Time triggers and receive Notifications and more. For example, you can connected the INSTAR Applets with the IFTTT Location service and to turn off the alarm when you arrive at home. You can also use the INSTAR Applets to build your own automation connecting them to other third-party Applets.",
  "image": "../MD_SearchThumb_Cloud_IFTTT.png",
  "social": "/images/Search/MD_SearchThumb_Cloud_IFTTT.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-INSTAR-Cloud-IFTTT_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='INSTAR Cloud IFTTT User Guide' dateChanged='2020-10-21' author='Mike Polinowski' tag='INSTAR IP Camera' description='On the IFTTT Platform, we provided a service named INSTAR. The INSTAR Applets provides a way for you to control some settings of your INSTAR Camera or INSTAR Cloud with IFTTT. You can connect INSTAR to Location and Date & Time triggers and receive Notifications and more. For example, you can connected the INSTAR Applets with the IFTTT Location service and to turn off the alarm when you arrive at home. You can also use the INSTAR Applets to build your own automation connecting them to other third-party Applets.' image='/images/Search/MD_SearchThumb_Cloud_IFTTT.png' twitter='/images/Search/MD_SearchThumb_Cloud_IFTTT.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/INSTAR_Cloud/IFTTT/User_Guide/How_to_use_INSTAR_Applets/' locationFR='/fr/Motion_Detection/INSTAR_Cloud/IFTTT/User_Guide/How_to_use_INSTAR_Applets/' crumbLabel="IFTTT" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "wie-verwendet-man-instar-applets",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#wie-verwendet-man-instar-applets",
        "aria-label": "wie verwendet man instar applets permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Wie verwendet man INSTAR Applets?`}</h2>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#wie-verwendet-man-instar-applets"
        }}>{`Wie verwendet man INSTAR Applets?`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#applets"
            }}>{`Applets`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#services"
            }}>{`Services`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h3 {...{
      "id": "from-tab-applets",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#from-tab-applets",
        "aria-label": "from tab applets permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`From Tab Applets`}</h3>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 01`}</strong>{`:  After logined in IFTTT,  click "Explore" , and enter "INSTAR" in search box. After result comes out, you can choose any one you want connect.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "881px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e2f8be0ef85dc7926ad2556476c94a7d/96658/explore-applet-01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAByUlEQVQoz4WRX2vTUBjG+7EUvFEQdzNBnDoUZAoO5lTchfsEXigoeCuCH2D+2YVu066dY9ZOdNhonf/W1W20zuTkJE3SNolZzk8SzTqd4AMPL+c95/3xvJzc+2qVpbcacq7M+q27fL15B6v0mkRxtMXfCsIQ29AxV1doCQPXa+M4DlEU0el0yPEfSSmpVCpUq1Vc1911r5T645xLGpkThWGI5zi4wsD3PIRpomlaCtR1nSAI0iTtdjt9m0Ez5zJQVoVlsaxpLBUL1D8sY0iJZbdS64Zg89smpmGwVq+ndedsCuw1dkT3bNzaRyLbhCiAsIMKOxB2CaIQ3XPxUf9cfTthrBSLazZPv0gKnwT3X9Yo1wyKqzbTny1KjYAXQiFWmtgz83TLFbqlN3RfvUPFcS9hBgy2FOPPbYbykqG8xd57kuGC5OIzi0vzNiOzJscXfJ7ceEDryAU2+kdp9A3THLhM7Ae7gVGsGJ2zODQp6JsU7JvQOV9MoBYnpiX9D3UOFnxmr08g9p9m/cAZNvYM/gIGvc/JJauq38DxBYujjwSnZkwGp0yuLtqcy5sMJL3HBmfLPqXbU4jDIzROXqF5bIzvY9eIwx/bwJ9iL4pv9bkMWgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e2f8be0ef85dc7926ad2556476c94a7d/e4706/explore-applet-01.avif 230w", "/en/static/e2f8be0ef85dc7926ad2556476c94a7d/d1af7/explore-applet-01.avif 460w", "/en/static/e2f8be0ef85dc7926ad2556476c94a7d/5334c/explore-applet-01.avif 881w"],
              "sizes": "(max-width: 881px) 100vw, 881px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e2f8be0ef85dc7926ad2556476c94a7d/a0b58/explore-applet-01.webp 230w", "/en/static/e2f8be0ef85dc7926ad2556476c94a7d/bc10c/explore-applet-01.webp 460w", "/en/static/e2f8be0ef85dc7926ad2556476c94a7d/8e8eb/explore-applet-01.webp 881w"],
              "sizes": "(max-width: 881px) 100vw, 881px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e2f8be0ef85dc7926ad2556476c94a7d/81c8e/explore-applet-01.png 230w", "/en/static/e2f8be0ef85dc7926ad2556476c94a7d/08a84/explore-applet-01.png 460w", "/en/static/e2f8be0ef85dc7926ad2556476c94a7d/96658/explore-applet-01.png 881w"],
              "sizes": "(max-width: 881px) 100vw, 881px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e2f8be0ef85dc7926ad2556476c94a7d/96658/explore-applet-01.png",
              "alt": "explore-applet-01.png",
              "title": "explore-applet-01.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 02`}</strong>{`:  After clicked the Applet you want to connect, you will see this page. `}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "879px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9a3379e5f030adfde027afe5b5ab1c38/bcb8c/explore-applet-02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAABuUlEQVQoz3WQzWsTQRjGxw88eCp49n/pJk012lqLNw+FFoQeqj0piGePHvRQqSepWoVsdhMjaTBpsFkQKXiS4qGtUIrizmwb7foRk2Z/MpPGNts68OOZmX2f931mxWQ54FotZOruAo+m7jN74wF3bs1hOQEDtm9Ias1Ko+cdyaW84vJLxXBemf1QTnHR9bld/YLQH0YKAen5DUbnVhl9ssbQ008ks5KB7H6zbkOtqaxkcE/38RnO+Yi0K9FYjqLf3TIk3MCYNecc+a9ht+nRSCxbIvQhYSZ3npfS54xvnnO1GDBWCrAyPv0Z32gq2zsgjjjqUhuvFBQ3l7aZrirD9UXF9KJirCi54MqeX9HT0LI7k7votDpNOhcw+SZkvNZkwmsx4TUZ91qMvG6QdDqp416NWKu36KXJ+rddPn4Nsd+tMvO8wMP5PLMvXjHzLMdju8jK5jbr39umNu4X/Gdtbnwmca/CsROnOC0EZ8/0cVwITgrBytvaXlX7kE+0o4iD7LYjIuDHTsjy+w9UFkpUSyVqlQpL5TLLnsefn79Mja6N+0UURcTR63ejwZaS1Ot1dsLwUJJuXTzhX+p6eEt2LdTzAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9a3379e5f030adfde027afe5b5ab1c38/e4706/explore-applet-02.avif 230w", "/en/static/9a3379e5f030adfde027afe5b5ab1c38/d1af7/explore-applet-02.avif 460w", "/en/static/9a3379e5f030adfde027afe5b5ab1c38/401c6/explore-applet-02.avif 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9a3379e5f030adfde027afe5b5ab1c38/a0b58/explore-applet-02.webp 230w", "/en/static/9a3379e5f030adfde027afe5b5ab1c38/bc10c/explore-applet-02.webp 460w", "/en/static/9a3379e5f030adfde027afe5b5ab1c38/6f82a/explore-applet-02.webp 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9a3379e5f030adfde027afe5b5ab1c38/81c8e/explore-applet-02.png 230w", "/en/static/9a3379e5f030adfde027afe5b5ab1c38/08a84/explore-applet-02.png 460w", "/en/static/9a3379e5f030adfde027afe5b5ab1c38/bcb8c/explore-applet-02.png 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9a3379e5f030adfde027afe5b5ab1c38/bcb8c/explore-applet-02.png",
              "alt": "explore-applet-02.png",
              "title": "explore-applet-02.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 03`}</strong>{`:  Clicking "Connect" button, it will redirect the INSTAR Authorization page. You may read INSTAR privacy policy and terms & conditions, then put your INSTAR Cloud username and password. `}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "886px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2481ef91dc3089008d91de3b59bdef6d/cc155/explore-applet-03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "84.34782608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsTAAALEwEAmpwYAAACI0lEQVQ4y41Ta4vaQBT1//+BfupjW5FdKJRSC4sg+M0PIqgYk0023VXzfqkxTszzlDttJOi07MBlJpN7zz33zrkd/F11XV/2xmjZto0kSSCtVhiPx6iqSujX7B201rUTYwy9Xg+j0Qj9fh/dbhdFUbwN8Jrh9Z1ovRkwCAK4roswDPnZ8zzOjMptMxOZEDDLMt63OI6R5znKshRam/l/AQkkTVMeJCi0CeD/KXkbuHP7IEB8PMKyLJiWDcfz4Xo+LMeFE0TcbM9HFCdgLMVkMsFwOMRyueQt6rQ4XzLlLIFhWkgCG2noIPEtHD0LKWNgaQp2iFAUJf8eDAaYTqecgCzLtwyppO+vGd7JZ3x5zvFZy3Gv53jQM3xSGO4Uhg8yw9eXHGvHx+DxEVEU8dKpTULAn3qM9zMfd4sAH+cBelKEb/oJ9+oJD9oJvacEP9YZDC/Eyy+dl346nXA4HMSP8qpICDY6WOggdg1UyR5iIVZ/WkCtYOzfgKr2DGklQ3lSsZRWMG0HZQ0UVY2yqvlOA3jOMq7R8/nMZbbf78WA1FxFUbDdbrFer3lQo7v2dNAdlUqiJ1A6d0RzbBgGlwGZqqpcDqLxI73SP5oqemWKu2FI46VpGhaLBWazGT+bpsl7RAyoNDLyI2Y0mmSO48D3fTEgMZvP53wnUAKXJOlyT3Y8Hi/+FNskEAJSts1mg91ux/VF/SGNUYl0JqP+kW9zR+wpyW+23RhjJjSQUwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2481ef91dc3089008d91de3b59bdef6d/e4706/explore-applet-03.avif 230w", "/en/static/2481ef91dc3089008d91de3b59bdef6d/d1af7/explore-applet-03.avif 460w", "/en/static/2481ef91dc3089008d91de3b59bdef6d/d297b/explore-applet-03.avif 886w"],
              "sizes": "(max-width: 886px) 100vw, 886px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2481ef91dc3089008d91de3b59bdef6d/a0b58/explore-applet-03.webp 230w", "/en/static/2481ef91dc3089008d91de3b59bdef6d/bc10c/explore-applet-03.webp 460w", "/en/static/2481ef91dc3089008d91de3b59bdef6d/b4685/explore-applet-03.webp 886w"],
              "sizes": "(max-width: 886px) 100vw, 886px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2481ef91dc3089008d91de3b59bdef6d/81c8e/explore-applet-03.png 230w", "/en/static/2481ef91dc3089008d91de3b59bdef6d/08a84/explore-applet-03.png 460w", "/en/static/2481ef91dc3089008d91de3b59bdef6d/cc155/explore-applet-03.png 886w"],
              "sizes": "(max-width: 886px) 100vw, 886px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2481ef91dc3089008d91de3b59bdef6d/cc155/explore-applet-03.png",
              "alt": "explore-applet-03.png",
              "title": "explore-applet-03.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 04`}</strong>{`:  After authorized successfully, it will show you Applet Settings. You can change these settings as you want.
After finished, click "Save" button.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "889px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/118dcaf807cb8d9535ba2ee5ba619020/83e77/explore-applet-04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAABcElEQVQ4y41Ta6uCQBT0//+chD4U9amiwhAjMil6h4G9ISx7zGUOrGx793Y9cFiU4+zMmdGBUe/3W84sy9DtdtFqtdDv99HpdNBsNuXd/X7/mNXL4UuzVc1mM+kkSbDZbLBarTCZTPB4PGTu9Xr9+taxgQ2HQ1wuF1SrVQH8q2xkHFMqz0ajgcVigSAIsFwucT6fcb1epff7vTC+3W5W2Y5Nqud5wq5er6NcLsN1XVQqFZRKJdRqNfR6PbmIZcq2MhyNRmICdxaGoTxTuu/7skvzmw+GNkDu73A44Pl85oOKCQ2hdD7re/wKGEURBoOBsGy32xKb7XaL9XotZxzH+WVfAVVzmK0Y6Uxtmf1XMlnM53NZfJqmuWQFzFiRbWHJx+MRu91O9qjioZeKUWHA6XQqOaTLPAlA6fzleIE+W9hlBphM2afTKW+yVmsw2VlNYRGMeaNs7k4Pr25YIZcVIBc/Ho9FsmqaxIDTMAVqAv4A82iGE/agkPQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/118dcaf807cb8d9535ba2ee5ba619020/e4706/explore-applet-04.avif 230w", "/en/static/118dcaf807cb8d9535ba2ee5ba619020/d1af7/explore-applet-04.avif 460w", "/en/static/118dcaf807cb8d9535ba2ee5ba619020/83101/explore-applet-04.avif 889w"],
              "sizes": "(max-width: 889px) 100vw, 889px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/118dcaf807cb8d9535ba2ee5ba619020/a0b58/explore-applet-04.webp 230w", "/en/static/118dcaf807cb8d9535ba2ee5ba619020/bc10c/explore-applet-04.webp 460w", "/en/static/118dcaf807cb8d9535ba2ee5ba619020/ade25/explore-applet-04.webp 889w"],
              "sizes": "(max-width: 889px) 100vw, 889px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/118dcaf807cb8d9535ba2ee5ba619020/81c8e/explore-applet-04.png 230w", "/en/static/118dcaf807cb8d9535ba2ee5ba619020/08a84/explore-applet-04.png 460w", "/en/static/118dcaf807cb8d9535ba2ee5ba619020/83e77/explore-applet-04.png 889w"],
              "sizes": "(max-width: 889px) 100vw, 889px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/118dcaf807cb8d9535ba2ee5ba619020/83e77/explore-applet-04.png",
              "alt": "explore-applet-04.png",
              "title": "explore-applet-04.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 05`}</strong>{`:  Finally, you can see it has already show "Connected". That means you have activated this Applet.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "879px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b9ec4ca6c347fbb258fe67e719e85bdf/bcb8c/explore-applet-06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAABu0lEQVQoz3WQTU8TURSGbzDqQhesjRt/ibQUhAiC1I0bEz9WCsrCDRt37tlgWBhF7MKk0xlnqNVEO1pDU/d+pBpp3JAyd2ZsG0rCCJ3HzPQjdagnee65yTnve869Yj7vcnuzyeLDHE8Wlnl8d5kHSykS+m8mVIsJVXJBlb089dJmbsMmmbW5bLTv07pNUt9hyawiZg2bmQ2HydQvkmtlkmvfmV7fIp6RjCoWccUKc5dERjI2gFldMmdIxKQmCYipDiOaGxLT3J7BuCp7JonOkChBbcawQx/Rm9xhLNgqbXFJt7mac7j2xuF82mIkbRFLtzccRKzjIwYVA/GVrM39Dy6LpsM902E+b7OQbw8I/rP7/Cgi3vmnfgLDi7rDnaLHjYLHzYLHrY8e1wv7TBkuoxnJIF2A2Kof0M/P2h8qjUO+VnZ4+kJn5ZnCakrj0brCynMNpfSD8nadSkTXRXAkWuH57VOR40Jw+sQxzp09w/DJIYZODTO+WqK6XeV/IVq+Tz+HLT8s7NqSkmmSf5Xl/escm+/eUjRNPn8pc7C3R9AW1QYc2dD324b7nketVqfeaLDbbPZ3/NMX3fAvct55PMIulWwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b9ec4ca6c347fbb258fe67e719e85bdf/e4706/explore-applet-06.avif 230w", "/en/static/b9ec4ca6c347fbb258fe67e719e85bdf/d1af7/explore-applet-06.avif 460w", "/en/static/b9ec4ca6c347fbb258fe67e719e85bdf/401c6/explore-applet-06.avif 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b9ec4ca6c347fbb258fe67e719e85bdf/a0b58/explore-applet-06.webp 230w", "/en/static/b9ec4ca6c347fbb258fe67e719e85bdf/bc10c/explore-applet-06.webp 460w", "/en/static/b9ec4ca6c347fbb258fe67e719e85bdf/6f82a/explore-applet-06.webp 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b9ec4ca6c347fbb258fe67e719e85bdf/81c8e/explore-applet-06.png 230w", "/en/static/b9ec4ca6c347fbb258fe67e719e85bdf/08a84/explore-applet-06.png 460w", "/en/static/b9ec4ca6c347fbb258fe67e719e85bdf/bcb8c/explore-applet-06.png 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b9ec4ca6c347fbb258fe67e719e85bdf/bcb8c/explore-applet-06.png",
              "alt": "explore-applet-06.png",
              "title": "explore-applet-06.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br /><br /><br />
    <h3 {...{
      "id": "from-tab-services",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#from-tab-services",
        "aria-label": "from tab services permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`From Tab Services`}</h3>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 01`}</strong>{`:  If you want to check how many Applets of Service INSTAR, you can choose tab Services after search results comes out.Then choose service INSTAR.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "881px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b0db0da39cbb9fb36aac1dcf384162f8/96658/explore-service-01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAABu0lEQVQoz42SO28TQRRG/ZPooAIqhGjokBAI8SdoaKipqehoggJGASGFh6EC5ADuKFAsHDvEa7wve707s4/Z3ZmDdoxDYlNwpat5aO653/00rW63S7+/j9/eZfzwMYcPHpGNxjSh65r1yPKcyHOZDw9YhAGJECRJQlmWZFlGK01TlFIoNyD7OSE/mmBUeQyI45jBYIDjOLaoCVNV6FRSVxW11mhdY4xBa01rXYEBiiInF8IWDIdDOp0OvV6P+XxOqRR5UaDKCmM2BqDVkG1qY2m/plO+fd7jy/t3OIMfzKLIjtUo9X0f1/MIw9A2aka1IlYMY5YKjW21bFeXChnNCCdjVCoo8oxMCoSUZGlK4nv4hyNEGFBVFX/rl2trdai1YVHUJCXM8honksQlNqXSNF6nUhK7U7zRkDQMEELY+2O7GqD+AzxKKm68Drm2G3LzzYzbnYjrr0Kuvgy4/zW2E+gTpp2075TC1aODRcmlHZ/zbZ9bb2fc24u5/Dzg7LbH3U/RhlfruQEcRCUX2h7ntj2uvAgs9OIznzNbLnc+nAZu/Ix/edj41+5LnuxLnval3Tfr1nfBRyf/b+BvYaHxNXj5RaAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b0db0da39cbb9fb36aac1dcf384162f8/e4706/explore-service-01.avif 230w", "/en/static/b0db0da39cbb9fb36aac1dcf384162f8/d1af7/explore-service-01.avif 460w", "/en/static/b0db0da39cbb9fb36aac1dcf384162f8/5334c/explore-service-01.avif 881w"],
              "sizes": "(max-width: 881px) 100vw, 881px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b0db0da39cbb9fb36aac1dcf384162f8/a0b58/explore-service-01.webp 230w", "/en/static/b0db0da39cbb9fb36aac1dcf384162f8/bc10c/explore-service-01.webp 460w", "/en/static/b0db0da39cbb9fb36aac1dcf384162f8/8e8eb/explore-service-01.webp 881w"],
              "sizes": "(max-width: 881px) 100vw, 881px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b0db0da39cbb9fb36aac1dcf384162f8/81c8e/explore-service-01.png 230w", "/en/static/b0db0da39cbb9fb36aac1dcf384162f8/08a84/explore-service-01.png 460w", "/en/static/b0db0da39cbb9fb36aac1dcf384162f8/96658/explore-service-01.png 881w"],
              "sizes": "(max-width: 881px) 100vw, 881px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b0db0da39cbb9fb36aac1dcf384162f8/96658/explore-service-01.png",
              "alt": "explore-service-01.png",
              "title": "explore-service-01.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 02`}</strong>{`:  After going to INSTAR home page, you can click "Connect" button or you also can choose any one of Applets you want to connect. `}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "878px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d2694bb9da17232681a3ad6032dee41b/94829/explore-service-02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAACMklEQVQoz32SzU8TURTF538yxip+BMHyIV2AKGKMMeLCICuiMYYYw8JEElcmhoX/QDv9mFLbqti0kBZKrcSQaMQKcQFWZ97MdDpNy4zWHnx3qEWsTvJbvDvnnvfePU9whxna0RNm6AoxXIhruLtkYDCqolty6u7/IHQGFbTjdIjhZEDBxecabqUNDMVUnAo49X/1cAQu2E/zBzc7G1FxO21gYqGEOxkDbomR6f5ND/KXYXeIUeNARCWG4xpxLqbRmm9yRnLG0c5U4HPhJl178AbPntGlFxpGYgyjcQXno4yuz6/ueaaiN9zq4f1NH+GITwbnqKgQLp8Cp6bguCij/8kKBh4n0TebR4fo1F0+Ga6mXmzqHYTwZg3SRg2+QvUPghs1iIUqxHUTvg8mAh8rVPN/amm9hSqtpc0WAgB8Lv/AdK6MB6/LeJg3MbVcxuyaiUerJqZzJmZWK5hMG7i/YuDpu8pv7cwbE/eyBtRaHc2PDJeKFq4ldNxIljCeKmEwpuFmUsN4Ssf1hE4p988x9IUVTC0bGEvouDKv00x5MO9Vm8zsesMxzH2zMBxTScCD4E9mclHH1XmerorLLzX0zjGMxPkzKlEwPOnOIEOPxFAofSfDeqPROuExUUGH3xn0Ia+MiaQGT4RRSDyEw14ZQ1GGsVcaBXPCr1APf5fr+gFDdaeO7FcbmS8WFrctJLcsvFVs5GUbmaKFhW0LqS0L2aKFNWbTAXiNw/Wm/ZMMG78MdwFg4OrE1ufgYAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d2694bb9da17232681a3ad6032dee41b/e4706/explore-service-02.avif 230w", "/en/static/d2694bb9da17232681a3ad6032dee41b/d1af7/explore-service-02.avif 460w", "/en/static/d2694bb9da17232681a3ad6032dee41b/b7a7f/explore-service-02.avif 878w"],
              "sizes": "(max-width: 878px) 100vw, 878px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d2694bb9da17232681a3ad6032dee41b/a0b58/explore-service-02.webp 230w", "/en/static/d2694bb9da17232681a3ad6032dee41b/bc10c/explore-service-02.webp 460w", "/en/static/d2694bb9da17232681a3ad6032dee41b/6749f/explore-service-02.webp 878w"],
              "sizes": "(max-width: 878px) 100vw, 878px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d2694bb9da17232681a3ad6032dee41b/81c8e/explore-service-02.png 230w", "/en/static/d2694bb9da17232681a3ad6032dee41b/08a84/explore-service-02.png 460w", "/en/static/d2694bb9da17232681a3ad6032dee41b/94829/explore-service-02.png 878w"],
              "sizes": "(max-width: 878px) 100vw, 878px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d2694bb9da17232681a3ad6032dee41b/94829/explore-service-02.png",
              "alt": "explore-service-02.png",
              "title": "explore-service-02.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 03`}</strong>{`:  After that, it will redirect the INSTAR Authorization page. You may read INSTAR privacy policy and terms & conditions, then put your INSTAR Cloud username and password. `}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "886px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2481ef91dc3089008d91de3b59bdef6d/cc155/explore-service-03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "84.34782608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsTAAALEwEAmpwYAAACI0lEQVQ4y41Ta4vaQBT1//+BfupjW5FdKJRSC4sg+M0PIqgYk0023VXzfqkxTszzlDttJOi07MBlJpN7zz33zrkd/F11XV/2xmjZto0kSSCtVhiPx6iqSujX7B201rUTYwy9Xg+j0Qj9fh/dbhdFUbwN8Jrh9Z1ovRkwCAK4roswDPnZ8zzOjMptMxOZEDDLMt63OI6R5znKshRam/l/AQkkTVMeJCi0CeD/KXkbuHP7IEB8PMKyLJiWDcfz4Xo+LMeFE0TcbM9HFCdgLMVkMsFwOMRyueQt6rQ4XzLlLIFhWkgCG2noIPEtHD0LKWNgaQp2iFAUJf8eDAaYTqecgCzLtwyppO+vGd7JZ3x5zvFZy3Gv53jQM3xSGO4Uhg8yw9eXHGvHx+DxEVEU8dKpTULAn3qM9zMfd4sAH+cBelKEb/oJ9+oJD9oJvacEP9YZDC/Eyy+dl346nXA4HMSP8qpICDY6WOggdg1UyR5iIVZ/WkCtYOzfgKr2DGklQ3lSsZRWMG0HZQ0UVY2yqvlOA3jOMq7R8/nMZbbf78WA1FxFUbDdbrFer3lQo7v2dNAdlUqiJ1A6d0RzbBgGlwGZqqpcDqLxI73SP5oqemWKu2FI46VpGhaLBWazGT+bpsl7RAyoNDLyI2Y0mmSO48D3fTEgMZvP53wnUAKXJOlyT3Y8Hi/+FNskEAJSts1mg91ux/VF/SGNUYl0JqP+kW9zR+wpyW+23RhjJjSQUwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2481ef91dc3089008d91de3b59bdef6d/e4706/explore-service-03.avif 230w", "/en/static/2481ef91dc3089008d91de3b59bdef6d/d1af7/explore-service-03.avif 460w", "/en/static/2481ef91dc3089008d91de3b59bdef6d/d297b/explore-service-03.avif 886w"],
              "sizes": "(max-width: 886px) 100vw, 886px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2481ef91dc3089008d91de3b59bdef6d/a0b58/explore-service-03.webp 230w", "/en/static/2481ef91dc3089008d91de3b59bdef6d/bc10c/explore-service-03.webp 460w", "/en/static/2481ef91dc3089008d91de3b59bdef6d/b4685/explore-service-03.webp 886w"],
              "sizes": "(max-width: 886px) 100vw, 886px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2481ef91dc3089008d91de3b59bdef6d/81c8e/explore-service-03.png 230w", "/en/static/2481ef91dc3089008d91de3b59bdef6d/08a84/explore-service-03.png 460w", "/en/static/2481ef91dc3089008d91de3b59bdef6d/cc155/explore-service-03.png 886w"],
              "sizes": "(max-width: 886px) 100vw, 886px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2481ef91dc3089008d91de3b59bdef6d/cc155/explore-service-03.png",
              "alt": "explore-service-03.png",
              "title": "explore-service-03.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 04`}</strong>{`:  After authorized successfully. You are free to connect any one of INSTAR Applets. `}</p>
    </blockquote>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      